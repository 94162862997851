import { FaqItem } from '../models/FaqItem'; 
import logger from '../utils/logger';
import apiClient from '../utils/apiClient';
import fallbackValue from './fallbackService';

export const faqService = async () => {
    try {
        const response = await apiClient.get('/v1/faq');
        const { result } = response.data;
        return result.faq.map(item => new FaqItem(item.url, item.title));
    } catch (error) {
        logger.handleError(error);
        const fallbackData = await fallbackValue();
        if (!fallbackData.faq) {
          throw new Error('Fallback data failed to retrieve');
        }
        return await fallbackData.faq.map(item => new FaqItem(item.url, item.title));
    }
};