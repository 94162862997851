import '../styles/styles.css';

const FAQ = ({ faqItems }) => { 
    return(
        <div>
        {faqItems && faqItems.length !== 0 && <div className="faq-container">
            <p className="container-title">Frequently Asked Questions (FAQ)</p>
            <div className="faq-card-container">
                {faqItems.map((item, index) => (
                    <div key={index} className="faq-card" onClick={() => window.open(item.url, '_blank')}>
                        <p>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>}
    </div>
    )
}

export default FAQ