import React from "react";
import '../styles/styles.css';
const TopProduct = ({ recommendItems, searchValue }) => { 

    return(
        <div className="top-product-container">
            <p className="no-result-title">Sorry, we couldn't find any matches for ‘{searchValue}’</p>
            {recommendItems && recommendItems.length !== 0 && <div> 
                <p><b>What You May Like</b></p>
                <div className="top-product-section">
                    {recommendItems.map((item, index) => (
                        <div key={index} className="top-product-item" onClick={() => window.open(item.url, '_blank')}>
                            <p>{item.title}</p>
                        </div>
                    ))}
                </div>
            </div>}  
        </div>
    )
}

export default TopProduct