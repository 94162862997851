import { ResultData } from '../models/ResultItem'; 
import apiClient from '../utils/apiClient';
import logger from '../utils/logger';

export const resultService = async (searchValue, currentPage, pageSize) => {
    try {
        const response = await apiClient.get('/v1/search', {
            params:{
                q: searchValue,
                page: currentPage,
                pageSize: pageSize
            }
        });
        
        return new ResultData(response.data.results, response.data.response_header.totalPage)
    } catch (error) {
        logger.handleError(error);
        throw(error);   
    }
};