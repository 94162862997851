import '../styles/styles.css';
const TopPromo = ({ promoItems }) => { 

    return(
        <div>
            {promoItems && promoItems.length !== 0 && <div className="top-promo-container">
                <p className="top-promo-title">You Might Be Interested With</p>
                <div className="top-promo-card-container">
                    {promoItems.map((item, index) => (
                        <div key={index} className="top-promo-card" onClick={() => window.open(item.url, '_blank')}>
                            <div className="promo-image-container">
                                <img src={item.image} alt="promotion" className="promo-image"/>
                            </div>
                            <div className="promo-details">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    )
}

export default TopPromo