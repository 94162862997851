import { PromoItem } from '../models/PromoItem'; 
import logger from '../utils/logger';
import apiClient from '../utils/apiClient';
import fallbackValue from './fallbackService';

export const promoService = async () => {
    try {
        const response = await apiClient.get('/v1/promo');
        const { result } = response.data;
        return result.promo.map(item => new PromoItem(item.title, item.image, item.url));
    } catch (error) {
        logger.handleError(error);
        const fallbackData = await fallbackValue();
        if (!fallbackData.promo) {
          throw new Error('Fallback data failed to retrieve');
        }
        return fallbackData.promo.map(item => new PromoItem(item.title, item.image, item.url));
    }
};
