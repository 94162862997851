import { suggestedService } from "../services/suggestedService";
import {useState, useEffect} from "react";
import logger from '../utils/logger';

export const useSuggestedItems = (searchValue) => {

    const [suggested, setSuggested] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchSuggested = async () => {

            try {
                const suggestedData = await suggestedService(searchValue);
                setSuggested(suggestedData);
            } catch (error) {
                logger.error('Error fetching search suggestions:', error)
                setError(error);
            }
        }
        fetchSuggested(); 
    }, [searchValue]);

    return {
        suggested,
        error
    }
}