export class ResultItem {
    constructor(title, url) {
        this.title = title;
        this.url = url;
    }
}

export class ResultData {
    constructor(results, totalPage){
        this.results = results.map(item => new ResultItem(item.title, item.url));
        this.totalPage = totalPage;
    }
}