import { trendingService } from "../services/trendingService";
import { promoService } from "../services/promoService";
import { faqService } from "../services/faqService";
import { recommendService } from "../services/recommendService";

export const getTrendingSearches = async () => {
    return await trendingService();
}

export const getPromoItems = async () => {
    return await promoService();
}

export const getFaqItems = async () => {
    return await faqService();
}

export const getRecommendItem = async () => {
    return await recommendService();
}


