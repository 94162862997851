import React, {useState, useEffect} from "react";
import './styles/styles.css';
import Bar from './components/bar.js';
import FAQ from './components/faq.js';
import SuggestedSearch from './components/suggested.js';
import Result from './components/result.js';
import TopPoduct from './components/recommended.js';
import TopPromo from './components/topPromo.js';
import TrendingSearches from './components/trending.js';
import ErrorPage from './components/errorPage.js';
import { getTrendingSearches, getPromoItems, getFaqItems, getRecommendItem } from "./presenters/searchPresenter.js";
import logger from './utils/logger';

function App() {
  const [typingStarted, setTypingStarted] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [submittedValue, setSubmittedValue] = useState('');

  const [isResult, setisResult] = useState(true);

  const [trendingSearches , setTrendingSearches] = useState([])
  const [promoItems , setPromoItems] = useState([])
  const [faqItems , setFaqItems] = useState([])
  const [recommendItems , setRecommendItems] = useState([])

  const [error, setError] = useState('');

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleError = (error) => {
    setError(error);
  };

  const handleSearch = (value) => {
    setSearchSubmitted(true);
    setError('');
    if(!isResult){
      setisResult(true);
    }
    if(value){
      setSubmittedValue(value);
      setSearchValue(value);
      setTypingStarted(true);
    }
  };

  const handleNoResult = () => {
    setisResult(false);
  }

  useEffect(() => {
    if(submittedValue !== searchValue){
      setSearchSubmitted(false);
      setError('');
    }
    const fetchData = async () => {
      try {
        if (!typingStarted){
          const trending = await getTrendingSearches();
          setTrendingSearches(trending);

          const faq = await getFaqItems();
          setFaqItems(faq);

        } 
      } catch (err) {
        logger.error(err)
      } 
    }

    fetchData();
  }, [searchValue,submittedValue,typingStarted]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isResult) {

          const promo = await getPromoItems();
          setPromoItems(promo);

          const recommend = await getRecommendItem();
          setRecommendItems(recommend);
        }
      } catch (err) {
        logger.error(err)
      } 
    }
    fetchData();
  }, [isResult]);

  return (
    <div className="app-container">
      {!error || error.code === "ECONNABORTED" ? (
        <div>
        <Bar 
          typingStarted={typingStarted} 
          setTypingStarted={setTypingStarted} 
          onSearchChange={handleSearchChange} 
          onSearch={handleSearch} 
          submittedValue={submittedValue} 
          setSubmittedValue={setSubmittedValue}
        />
        </div>
      ) : (<div></div>)}
      {error ? (
          <div><ErrorPage></ErrorPage></div>
        ) : !typingStarted ? (
          <div>
            <TrendingSearches 
              trendingSearches={trendingSearches} 
              onSearch={(value) => handleSearch(value)} 
            />
            <FAQ faqItems={faqItems} />
          </div>
        ) : !searchSubmitted ? (
          <div>
            <SuggestedSearch 
              searchValue={searchValue} 
              onSearch={(value) => handleSearch(value)}
            />
          </div>
        ) : isResult ? (
          <div>
            <Result 
              searchValue={searchValue} 
              onNoResult={handleNoResult}
              onError={handleError}
            />
          </div>
        ) : (
          <div>
            <TopPoduct 
              recommendItems={recommendItems} 
              searchValue={searchValue} 
            />
            <TopPromo promoItems={promoItems}/>
          </div>
      )}
    </div>
  );
}

export default App;
