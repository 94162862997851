import logger from '../utils/logger';
import { FALLBACK_URL } from '../config/default';

const fallbackValue = async () => {
    try {
        const response = await fetch(FALLBACK_URL);
        return response.json();
    } catch (error) {
        logger.handleError(error);
        throw error;
    }
}

export default fallbackValue;
