import logger from '../utils/logger';
import apiClient from '../utils/apiClient';
import fallbackValue from './fallbackService';

export const trendingService = async () => {
    try {
        const response = await apiClient.get('/v1/trending');
        const { result } = response.data;
        return result.trend;
        
    } catch (error) {
        logger.handleError(error);
        const fallbackData = await fallbackValue();
        if (!fallbackData.trend) {
          throw new Error('Fallback data failed to retrieve');
        }
        return fallbackData.trend;
    }
};