import axios from "axios";
import {
    APP_BACKEND_URL,
    AUTH_TOKEN
} from '../config/default';

const apiClient = axios.create({
    baseURL: APP_BACKEND_URL,
    headers: {
        Authorization: AUTH_TOKEN
    }
});

export default apiClient;