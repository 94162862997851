import React, {useState, useEffect} from "react";
import '../styles/styles.css';
import { IoSearchSharp } from "react-icons/io5";

const Bar = ({ onSearchChange, typingStarted, setTypingStarted, onSearch, submittedValue, setSubmittedValue }) =>{

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchBar = (event) => {
        let val = event.target.value;
        if (!typingStarted) {
            setTypingStarted(true);  
        }
        if (val.trim() === ''){
            setTypingStarted(false); 
        }
        setSearchQuery(val);
        onSearchChange(val);
    }

    const handleClearSearch = () => {  
        setSearchQuery('');
        setSubmittedValue('');
        if (typingStarted) {
            setTypingStarted(false);  
          }
    };

    const handleSubmit = () => {
        onSearch(searchQuery);  
    };

    useEffect(() => {
        setSearchQuery(submittedValue);
    }, [submittedValue]);

    useEffect(() => {
        const listener = event => {
            if (event.key === "Enter" || event.key === "NumpadEnter") {
                event.preventDefault();
                handleSubmit();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [searchQuery]); 

    return(
        <div className="bar-container">
            <div className="search-box"> 
                <input 
                    type="text" 
                    placeholder="What are you looking for today?" 
                    className="search-input"
                    value={searchQuery}
                    maxLength={256}
                    onChange={handleSearchBar}
                />
                {searchQuery && (
                    <button className="clear-button" onClick={handleClearSearch}>
                    <p>Clear</p>
                    </button>
                )}
                <button className="search-button" onClick={handleSubmit}>
                    <IoSearchSharp className="search-icon"/>
                </button>  
            </div> 
            <div className="close-box"> 
                <div className="close-button">
                </div>  
            </div>
        </div>
    )
}

export default Bar