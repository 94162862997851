import { resultService } from "../services/resultService";
import {useState, useEffect} from "react";
import logger from '../utils/logger';

export const useResultItems = (searchValue, onNoResult) => {
    const [result, setResult] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState('');

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
      };

    useEffect(() => {
        const fetchResult = async () => {

            try {
                const resultItem = await resultService(searchValue, currentPage, pageSize);
                setResult(resultItem.results);
                setTotalPages(resultItem.totalPage);
                if(resultItem.results.length <= 0){
                    onNoResult()
                }
            } catch (error) {
                logger.error('Error fetching search result:', error)
                setError(error);
            }
        }
        fetchResult(); 
    }, [searchValue, currentPage, pageSize, onNoResult]);

    return {
        result,
        totalPages,
        handlePageChange,
        error
    }
}