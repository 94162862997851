const logger = {
  log: (message) => {
    console.log(`[LOG]: ${message}`);
  },
  error: (message) => {
    console.error(`[ERROR]: ${message}`);
  },
  handleError: (error) => {
    logger.error(`An error occurred: ${error.message}`);
  }
};

export default logger;