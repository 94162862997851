import React, {useEffect} from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import '../styles/styles.css';
import ReactPaginate from 'react-paginate';
import { useResultItems } from "../presenters/resultPresenter";

const Result = ({searchValue, onNoResult, onError}) => { 

    const {result, totalPages, handlePageChange, error} = useResultItems(searchValue, onNoResult);

    useEffect(() => {
        onError(error); 
      }); 

    return(
        <div className="suggested-container">
            {result && result.length !== 0 && <div><div className="suggested-card-container">
                <p className="result-container-title">Here's what we found for <b>‘{searchValue}’</b></p>
                    {result.map((item, index) => (
                        <div key={index} className="suggested-card" onClick={() => window.open(item.url, '_blank')}>
                            <a href={item.url} target="_blank" className="result-item">{item.title}</a>
                        </div>
                    ))}
                </div>
                
                <ReactPaginate
                    previousLabel={<MdNavigateBefore />}
                    nextLabel={<MdNavigateNext />}
                    breakLabel={'...'}
                    pageCount={totalPages} 
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange} 
                    containerClassName={'paging-container'}
                    activeClassName={'active'}
                    previousClassName={'paging'}
                    nextClassName={'paging'}
                />
            </div>
            }
        </div>
    )
}

export default Result