import React from "react";
import '../styles/styles.css';

const TrendingSearches = ({ trendingSearches, onSearch }) => { 

    const handleSubmit = (item) => {
        onSearch(item); 
      };

    return(
        <div>
            {trendingSearches && trendingSearches.length !== 0 && <div className="trending-container">
                <p className="container-title">Trending Searches</p>
                <div className="card-container">
                    {trendingSearches.map((item, index) => (
                        <div key={index} className="card" onClick={() => handleSubmit(item)}>
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    )
}

export default TrendingSearches
