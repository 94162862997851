import logger from '../utils/logger';
import apiClient from '../utils/apiClient';

export const suggestedService = async (searchValue) => {
    try {
        const response = await apiClient.get('/v1/suggest', {
            params:{
                q: searchValue
            }
        });

        return response.data.results;
    } catch (error) {
        logger.handleError(error);
        throw(error);
    }
};