import React from "react";
import DOMPurify from "dompurify";
import '../styles/styles.css';
import { useSuggestedItems } from "../presenters/suggestedPresenter";

const SuggestedSearch = ({searchValue, onSearch}) => { 

    const {suggested} = useSuggestedItems(searchValue);

    const handleSubmit = (item) => {
        onSearch(item); 
      };

    const escapeRegex = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };
    const safeSearchValue = escapeRegex(searchValue);
    const regex = new RegExp(safeSearchValue, 'gi');

    return(
        <div className="suggested-container">
            {suggested && suggested.length > 0 && <div className="suggested-card-container"><p className="container-title">Suggested Searches</p>
                {suggested.map((item, index) => {
                    return (
                    <div key={index} className="suggested-card">
                        <p onClick={() => handleSubmit(item)}>
                            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.replace(regex, (match) => `<strong>${match}</strong>`)) }} />
                        </p>
                    </div>
                )
            })}

            </div>}
        </div>
    )
}

export default SuggestedSearch