import { RecommendItem } from "../models/RecommendItem";
import logger from '../utils/logger';
import apiClient from '../utils/apiClient';
import fallbackValue from './fallbackService';

export const recommendService = async () => {
    try {
        const response = await apiClient.get('v1/recommend');
        const { result } = response.data;
        return result.recommend.map(item => new RecommendItem(item.url, item.title));
    } catch (error) {
        logger.handleError(error);
        const fallbackData = await fallbackValue();
        if (!fallbackData.recommend) {
          throw new Error('Fallback data failed to retrieve');
        }
        return fallbackData.recommend.map(item => new RecommendItem(item.url, item.title));
    }
}